import React, { useEffect, useState } from "react";
import SEO from "@/components/seo";
import Platform from "@/pages/platform";

const Index = () => {
  const [isStartPage, setStartPage] = useState(false);
  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    if (isBrowser) {
      window.location.pathname = "/platform/";
      setStartPage(window?.location?.pathname === "/platform/");
    }
  }, [isBrowser])

  return (
    <>
      {isStartPage && (
        <>
          <SEO />
          <Platform></Platform>
        </>
      )}
    </>
  );
};

export default Index;
